// src/components/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter, FaLinkedin, FaGithub, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';

interface SocialLink {
  name: string;
  url: string;
  icon: React.ReactNode;
}

const socialLinks: SocialLink[] = [
  { name: 'Twitter', url: 'https://x.com/GovernorShabbir', icon: <FaTwitter size={20} /> },
  { name: 'LinkedIn', url: 'https://www.linkedin.com/in/shabbir-governor-442842154/', icon: <FaLinkedin size={20} /> },
  { name: 'GitHub', url: 'https://github.com/shabbirg89', icon: <FaGithub size={20} /> },
];

const policyLinks = [
  { name: 'Terms & Conditions', path: '/terms' },
  { name: 'Privacy Policy', path: '/privacy' },
  { name: 'Refund Policy', path: '/refund' },
  { name: 'Digital Delivery Policy', path: '/digital-delivery' },
];

const Footer: React.FC = () => (
  <footer className="bg-black text-white">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-12 sm:py-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="space-y-6">
          <h2 className="text-xl sm:text-2xl font-bold">Shabbir Governor</h2>
          <p className="text-gray-400 text-sm">
            Connect with me on !!!
          </p>
          <div className="flex space-x-6">
            {socialLinks.map((link) => (
              <a
                key={link.name}
                href={link.url}
                className="text-gray-400 hover:text-white transition-colors duration-200"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={link.name}
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>
        <div>
          <h3 className="text-sm font-semibold text-gray-300 tracking-wider uppercase mb-4">
            Policies
          </h3>
          <ul className="space-y-3">
            {policyLinks.map((link) => (
              <li key={link.name}>
                <Link
                  to={link.path}
                  className="text-sm sm:text-base text-gray-400 hover:text-white transition-colors duration-200"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-sm font-semibold text-gray-300 tracking-wider uppercase mb-4">
            Contact Us
          </h3>
          <ul className="space-y-3 text-gray-400">
            <li className="flex items-center">
              <FaEnvelope className="mr-2 flex-shrink-0" />
              <span className="text-sm sm:text-base">shabbirg89@gmail.com</span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-sm font-semibold text-gray-300 tracking-wider uppercase mb-4">
            Operating Address
          </h3>
          <address className="text-gray-400 not-italic text-sm sm:text-base">
            <FaMapMarkerAlt className="inline-block mr-2 mb-1" />
            305, Mahalaxmi Blg, Ambaji Road,<br />
            Near Lalbatti<br />
            Virar, Maharashtra 401404<br />
            India
          </address>
        </div>
      </div>
      <div className="border-t border-gray-800 py-8">
        <p className="text-center text-sm sm:text-base text-gray-400">
          &copy; {new Date().getFullYear()} Shabbir Governor. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;